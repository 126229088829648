import React from 'react';
import { Box } from 'rebass';
import Layout from '../components/layout/Layout';
import MetaData from '../components/MetaData';
import ThankYouHero from '../sections/Landings/ThankYouHero';

const HireTopEngineersThankYouPage = () => (
  <>
    <MetaData
      title='Thank You'
      description="We're all set! A member of our sales team will reach out to you shortly."
    />
    <Layout selected='hire-top-engineers-thank-you'>
      <Box className='full-width' bg='nextonLightGrey' minHeight='calc(100vh - 100px)'>
        <ThankYouHero title="We're all set!" subtitle='A member of our sales team will reach out to you shortly.' />
      </Box>
    </Layout>
  </>
);

export default HireTopEngineersThankYouPage;
